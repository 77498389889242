import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import { Container } from './styles';

export default function Pagination({ children, meta, perPage, setPerPage, ...props }) {
  const { t } = useTranslation();
  const { from, to, total, current_page } = meta;

  return (
    <>
      {total > 0 && (
        <>
          <Container
            {...props}
            showTotal={
              () =>
                `${t('screens:pagination.showing')} ${from} ${t('screens:pagination.to')} ${to} ${t(
                  'screens:pagination.of'
                )} ${total} ${t('screens:pagination.records')}`
              // eslint-disable-next-line react/jsx-curly-newline
            }
            showSizeChanger={false}
            total={total}
            defaultPageSize={perPage}
            current={current_page}
          >
            {children}
          </Container>
          <Select
            name="perPage"
            onChange={(value) => {
              setPerPage(value);
            }}
            value={perPage}
            style={{ width: '70px' }}
          >
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={30}>30</Select.Option>
            <Select.Option value={40}>40</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </Select>
        </>
      )}
    </>
  );
}

Pagination.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  perPage: PropTypes.number,
  setPerPage: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  children: null,
  meta: {
    from: 0,
    to: 0,
    current_page: 1,
    total: 0,
  },
  perPage: 10,
};
