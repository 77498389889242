import styled, { createGlobalStyle } from 'styled-components';

export const DivTitle = styled.h1`
  color: ${(props) => props.theme.color1};
  font-size: 18px;
  line-height: 21px;
`;
export const GlobalStyle = createGlobalStyle`
  @media screen and (max-width: 575px) {
  .author-actions .ll-btn{
    margin-top: unset !important;
    margin-bottom: 25px;
  }
}
`;
