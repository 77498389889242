import React from 'react';

import { Route } from 'react-router-dom';
import { useAuth } from '~/contexts/auth';

import e404 from '~/pages/Errors/e404';
import PaymentThanks from '~/pages/PaymentThanks';
import CertificateVerify from '~/pages/CertificateVerify';
import ReceiptVerify from '~/pages/ReceiptVerify';

import SignRoutes from './SignRoutes';
import OtherRoutes from './OtherRoutes';

export default function Routes() {
  const context = useAuth();
  const { signed } = context;

  const PublicRoutes = (
    <>
      <Route exact path="/pagamento/obrigado" component={PaymentThanks} />
      <Route exact path="/certificado/verificador/:uuid" component={CertificateVerify} />
      <Route exact path="/comprovante/verificador/:uuid" component={ReceiptVerify} />
      <Route exact path="/404" component={e404} />
    </>
  );

  return signed ? <OtherRoutes other={PublicRoutes} /> : <SignRoutes other={PublicRoutes} />;
}
