/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { message, Spin, Col, Tag, Divider, Form, Input, Table as TableAntd, Typography, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FaFileAlt, FaCoins } from 'react-icons/fa';
import DefaultLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import { Table } from '~/components/Table';
import Row from '~/components/Row';
import Button from '~/components/Button';
import { decrypt } from '~/Utils/index';
import PageTitle from '~/components/PageTitle';
import api from '~/services/api';
import moment from 'moment';
import { DivTitle, Global } from './styles';
import 'antd/dist/antd.css';
import './index.css';

// Reusable Components
const EditableCard = ({ record, form, save, cancel, size, hasTitle }) => (
  <Card
    size={hasTitle ? 'small' : 'default'}
    style={{ width: size + 50, borderRadius: 10, backgroundColor: '#f0f2f5' }}
    title={hasTitle ? 'Dados do Crachá' : null}
  >
    <Form form={form} component={false}>
      <Form.Item
        label="Nome da instituição"
        name="badge_institution"
        style={{ margin: 0 }}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input style={{ width: size }} />
      </Form.Item>
      <Form.Item
        label="Nome"
        name="badge_name"
        style={{ margin: 0 }}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input style={{ width: size }} />
      </Form.Item>
      <span style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
        <Typography.Link onClick={() => save(record.id, form.getFieldsValue())} style={{ marginRight: 8 }}>
          Salvar
        </Typography.Link>
        <Typography.Link onClick={cancel}>Cancelar</Typography.Link>
      </span>
    </Form>
  </Card>
);

const NonEditableCard = ({ record, edit, editingKey, size, hasTitle }) => (
  <Card
    size={hasTitle ? 'small' : 'default'}
    style={{ width: size + 50, borderRadius: 10, backgroundColor: '#f0f2f5' }}
    title={hasTitle ? 'Dados do Crachá' : null}
  >
    <p>
      <b>Instituição: </b>
      {record.badge_institution}
    </p>
    <p>
      <b>Nome: </b>
      {record.badge_name}
    </p>
    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
        Editar
      </Typography.Link>
    </span>
  </Card>
);

export default function MySubscriptions() {
  const history = useHistory();
  const { t } = useTranslation();
  const [recordDataCongresses, setRecordDataCongresses] = useState([]);
  const [recordCourses, setRecordCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const person =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;
  const [form] = Form.useForm();

  const edit = (record) => {
    form.setFieldsValue({
      badge_institution: record.badge_institution || '',
      badge_name: record.badge_name || '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const updateSubscription = async (id, data) => {
    try {
      await api.put(`/subscriptions/${id}`, data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const data = await api.get(`/subscriptions/congress`);
      const courseData = await api.get('/subscriptions/union-all');
      await api.get('/me');

      if (data.length < 1 && courseData.data.length < 1) {
        message.info('Nenhuma inscrição encontrada!');
        setRecordCourses([]);
        setRecordDataCongresses([]);
      } else {
        setRecordCourses(courseData.data);
        setRecordDataCongresses(data.data);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const save = async (id, data) => {
    try {
      setLoading(true);
      await updateSubscription(id, { badge_institution: data.badge_institution, badge_name: data.badge_name });
      fetchScreenData();
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    } finally {
      setLoading(false);
    }
  };

  const handleCertificate = async (data) => {
    setLoading(true);
    try {
      await api
        .get(`/subscriptions/certificate/${data.uuid}`, {
          method: 'GET',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          const type = data.course === null ? 'congresso' : 'curso';
          const slug = data.course === null ? data.congress.slug : data.course.slug;

          link.setAttribute(
            'download',
            `certificado_${type}_${slug.replace('-', '_')}_${data.uuid}_${new Date().toLocaleDateString()}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response.status === 400) {
        message.error('Certificado incapaz de emissao.');
      }
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paidOutCongress = (record) => {
    if (!record?.congress?.id) return null;

    const type = 'congress';
    const typeId = record.congress.id;
    if (!record.paid_out && !record.tax_exempt) {
      return (
        <Button
          onClick={() => {
            history.push({
              pathname: '/pagamento',
              state: { type, typeId },
            });
          }}
          color="danger"
        >
          <FaCoins style={{ fontSize: '14px' }} /> Pagar agora
        </Button>
      );
    }
    return (
      <Tag color={record.paid_out ? 'green' : 'red'}>
        <b>{record.paid_out ? 'SIM' : 'NÃO'}</b>
      </Tag>
    );
  };

  const paidOutCourse = (record) => {
    const type = 'course';
    const typeId = record.course.id;
    if (!record.subscription.paid_out && !record.subscription.tax_exempt) {
      return (
        // make button red color
        <div>
          <Button
            onClick={() => {
              history.push({
                pathname: '/pagamento',
                state: { type, typeId },
              });
            }}
            color="danger"
          >
            <FaCoins style={{ fontSize: '14px' }} /> Pagar agora
          </Button>
        </div>
      );
    }
    return (
      <Tag color={record.subscription.paid_out ? 'green' : 'red'} style={{ marginLeft: '17%' }}>
        <b>{record.subscription.paid_out ? 'SIM' : 'NÃO'}</b>
      </Tag>
    );
  };

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'left',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortBy: 'id',
      width: 200,
      responsive: ['sm', 'md', 'lg'],
      editable: false,
      ellipsis: { showTitle: true },
    },
    {
      title: t('screens:subscriptions.data.uuid'),
      key: 'uuid',
      width: 300,
      responsive: ['sm', 'md', 'lg'],
      render: (record) => (
        <Tag color="gold">
          <b>{record.uuid && record.uuid.toUpperCase()}</b>
        </Tag>
      ),
    },
    {
      title: t('screens:subscriptions.data.congress_id'),
      key: 'congress.description',
      ellipsis: true,
      responsive: ['sm', 'md', 'lg'],
      render: (record) => record?.congress?.description,
    },
    {
      title: 'Crachá',
      key: 'congress.description',
      ellipsis: true,
      responsive: ['sm', 'md', 'lg'],
      editable: true,
      width: 382,
      render: (record) => {
        const editable = isEditing(record);
        return editable ? (
          <EditableCard record={record} form={form} save={save} cancel={cancel} size={300} />
        ) : (
          <NonEditableCard record={record} edit={edit} editingKey={editingKey} size={300} />
        );
      },
    },
    {
      title: t('screens:subscriptions.data.paid_out'),
      key: 'action',
      width: 200,
      responsive: ['sm', 'md', 'lg'],
      render: (text, record) => paidOutCongress(record),
    },
    {
      title: t('screens:subscriptions.data.uuid'),
      key: 'all',
      responsive: ['xs'],
      render: (record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Tag color="gold">
              <b>{record.uuid && record.uuid.toUpperCase()}</b>
            </Tag>
            <p>{record.congress.description}</p>
            <EditableCard record={record} form={form} save={save} cancel={cancel} size={210} hasTitle />
            <p style={{ paddingTop: 10 }}>{paidOutCongress(record)}</p>
          </>
        ) : (
          <>
            <Tag color="gold">
              <b>{record.uuid && record.uuid.toUpperCase()}</b>
            </Tag>
            <p>{record.congress.description}</p>
            <NonEditableCard record={record} edit={edit} editingKey={editingKey} size={210} hasTitle />
            <p style={{ paddingTop: 10 }}>{paidOutCongress(record)}</p>
          </>
        );
      },
    },
  ];

  const tableColumnsCourses = () => {
    const columns = [
      {
        title: t('screens:subscriptions.data.uuid'),
        key: 'id',
        width: 300,
        responsive: ['sm', 'md', 'lg'],
        render: (record) => (
          <Tag color="gold">
            <b>{record.subscription.uuid}</b>
          </Tag>
        ),
      },
      {
        title: 'Curso',
        key: 'course_description',
        ellipsis: true,
        responsive: ['sm', 'md', 'lg'],
        render: (record) => record.course.description,
      },
      {
        title: t('screens:subscriptions.data.paid_out'),
        key: 'action',
        width: 200,
        responsive: ['sm', 'md', 'lg'],
        render: (text, record) => paidOutCourse(record),
      },
      {
        title: t('screens:subscriptions.data.uuid'),
        key: 'all',
        responsive: ['xs'],
        render: (record) => (
          <>
            <Tag color="gold">
              <b>{record.subscription.uuid && record.subscription.uuid.toUpperCase()}</b>
            </Tag>
            <p>{record.course.description}</p>
            {paidOutCourse(record)}
          </>
        ),
      },
    ];

    return columns;
  };

  const expandedRowRender = (record) => {
    const isCongress = !!record?.subscription;
    const formattedDate =
      record.subscription?.created_at && moment(record.subscription.created_at).format('DD/MM/YYYY [às] HH:mm');
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {!isCongress ? (
            <>
              <h4>
                Congresso: <b>{record.congress && record.congress.description.toUpperCase()}</b>
              </h4>
              <Divider style={{ margin: '1rem 0 1rem 0' }} />
              <h4>
                Inscrição:{' '}
                <Tag color="gold">
                  <b>{record.uuid && record.uuid.toUpperCase()}</b>
                </Tag>
              </h4>
              <h4>
                Inscrição realizada em:{' '}
                <b>
                  {`${new Date(record.created_at).toLocaleDateString()} às
                  ${new Date(record.created_at).toLocaleTimeString()}`}
                </b>
              </h4>
              {record.certificate_issued_at && (
                <h4>
                  Certificado emitido em:{' '}
                  <b>
                    {`${new Date(record.certificate_issued_at).toLocaleDateString()} às
              ${new Date(record.certificate_issued_at).toLocaleTimeString()}`}
                  </b>
                </h4>
              )}
              <h4>
                Pago:{' '}
                <Tag color={record.paid_out ? 'green' : 'red'}>
                  <b>{record.paid_out ? 'SIM' : 'NÃO'}</b>
                </Tag>
              </h4>
              <h4>
                Isento:{' '}
                <Tag color={record.tax_exempt ? 'green' : 'red'}>
                  <b>{record.tax_exempt ? 'SIM' : 'NÃO'}</b>
                </Tag>
              </h4>
              <h4>
                Presença:{' '}
                <Tag color={record.presence ? 'green' : 'red'}>
                  <b>{record.presence ? 'SIM' : 'NÃO'}</b>
                </Tag>
              </h4>
              <h4>
                Nome para o crachá: <b>{record.badge_name}</b>
              </h4>
              <h4>
                Nome da instituição para o crachá: <b>{record.badge_institution}</b>
              </h4>
              <h4>
                Estrangeiro: <b>{record.foreign ? 'SIM' : 'NÃO'}</b>
              </h4>
              <h4>
                Tradutor: <b>{record.translator ? 'SIM' : 'NÃO'}</b>
              </h4>
              {record.presence && (record.paid_out || record.tax_exempt) && (
                <Button onClick={() => handleCertificate(record)} style={{ marginTop: '1rem' }} color="primary">
                  <FaFileAlt /> {!record.certificate_issued_at ? 'Emitir certificado' : 'Fazer donwload'}
                </Button>
              )}
            </>
          ) : (
            <>
              <h4>
                Congresso: <b>{record.congress.description.toUpperCase()}</b>
              </h4>
              <h4>
                Curso: <b>{record.course?.description.toUpperCase()}</b>
              </h4>
              <Divider style={{ margin: '1rem 0 1rem 0' }} />
              Inscrição:{' '}
              <Tag color="gold">
                <b>{record.subscription?.uuid}</b>
              </Tag>
              <h4>
                Inscrição realizada em: <b>{formattedDate}</b>
              </h4>
              <h4>
                Pago:{' '}
                <Tag color={record.subscription?.paid_out ? 'green' : 'red'}>
                  <b>{record.subscription?.paid_out ? 'SIM' : 'NÃO'}</b>
                </Tag>
              </h4>
              <h4>
                Professor(es): <b>{record.course?.professor.toUpperCase()}</b>
              </h4>
              <h4>
                Informações: <b>{record.course?.info.toUpperCase()}</b>
              </h4>
            </>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <DefaultLayout>
      <Global />
      <PageTitle title={person ? t('screens:subscriptions.data.mine') : null} />
      <Spin spinning={loading}>
        <Row>
          {recordDataCongresses && recordDataCongresses.length > 0 && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {!loading && (
                <Divider style={{ margin: '0 0 1.5rem 0' }}>
                  <DivTitle>Congressos</DivTitle>
                </Divider>
              )}
              <Row>
                <Form form={form} component={false}>
                  <TableAntd
                    columns={tableColumns}
                    expandable={{ expandedRowRender }}
                    loading={loading}
                    dataSource={recordDataCongresses}
                    rowKey="id"
                    bordered
                    pagination={{
                      onChange: cancel,
                    }}
                  />
                </Form>
              </Row>
            </Col>
          )}
          {recordCourses && recordCourses.length > 0 && (
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {!loading && (
                <Divider style={{ margin: '0 0 1.5rem 0' }}>
                  <DivTitle>Cursos</DivTitle>
                </Divider>
              )}
              <Row>
                <Table
                  columns={tableColumnsCourses()}
                  expandable={{ expandedRowRender }}
                  loading={loading}
                  dataSource={recordCourses}
                  rowKey="uuid"
                />
              </Row>
            </Col>
          )}
        </Row>
      </Spin>
    </DefaultLayout>
  );
}
